<template>
  <v-card>
    <v-card-title class="justify-center">
      <!--
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      
      <v-pagination
        v-model="page"
        :length="pages"
      ></v-pagination>
      -->
      <form>
        <v-text-field v-model="filtro" label="Buscar:" required></v-text-field>
        <v-btn class="mr-4" @click="buscar()">
          Buscar
        </v-btn>
      </form>

      <DatosBaseAgregar />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="datos"
      hide-default-footer
    ></v-data-table>

    <v-pagination
      v-model="page"
      :length="pages"
      v-on:input="fetchData()"
    ></v-pagination>
  </v-card>
</template>

<script>
import axios from "axios";
import DatosBaseAgregar from "@/components/DatosBaseAgregar";
export default {
  components: {
    DatosBaseAgregar
  },
  data() {
    return {
      url: process.env.VUE_APP_API,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      headers: [
        { text: "numeroregistro", align: "start", value: "numeroRegistro" },
        { text: "nombreasegurado", value: "nombreAsegurado" },
        { text: "apellidoasegurado", value: "apellidoAsegurado" },
        { text: "generoasegurado", value: "generoAsegurado" },
        { text: "estadoCivilAsegurado", valie: "estadoCivilAsegurado" },
        { text: "fechanacimientoasegurado", value: "fechaNacimientoAsegurado" },
        { text: "edadasegurado", value: "edadAsegurado" },
        { text: "numeroTelefonoTrabajo", value: "numeroTelefonoTrabajo" },
        { text: "numeroExtensionTrabajo", value: "numeroExtensionTrabajo" },
        { text: "numerodecasa", value: "numeroTelefonoCasa" },
        { text: "numeroTelefonoMovil", value: "numeroTelefonoMovil" },
        { text: "numeroFax", value: "numeroFax" },
        { text: "correotrabajo", value: "correoTrabajo" },
        { text: "correoPersonal", value: "correoPersonal" },
        { text: "direccion", value: "direccion" },
        { text: "numeroagenteproductor", value: "numeroAgenteProductor" },
        { text: "nombreagenteproductor", value: "nombreAgenteProductor" },
        { text: "empleador", value: "empleador" },
        { text: "idPersonal", value: "idPersonal" },
        { text: "plan-contrato-producto", value: "planContratoProducto" },
        { text: "producto", value: "producto" },
        { text: "deducibleomontoasegurado", value: "deducibleMontoAsegurado" },
        { text: "fechaaniversariopoliza", value: "fechaAniversarioPoliza" },
        { text: "prima_anual", value: "primaAnual" },
        { text: "estadodepolizaocontrato", value: "estadoPolizaContrato" },
        { text: "lineadenegocio", value: "lineaNegocio" },
        { text: "numeropolizacontrato", value: "numeroPolizaContrato" },
        { text: "fechaefectividad", value: "fechaEfectividad" },
        { text: "fecha_cancelacion", value: "fechaCancelacion" },
        { text: "nombredeldueñodelapoliza", value: "nombrePropietarioPoliza" },
        {
          text: "apellidodeldueñodelapoliza",
          value: "apellidoPropietarioPoliza"
        },
        { text: "fecharecibido", value: "fechaRecibido" },
        { text: "estadodelcliente", value: "estadoCliente" },
        { text: "exclusionesactivas", value: "exclusionesActivas" },
        { text: "frecuenciapago", value: "frecuenciaPago" }
      ]
    };
  },
  methods: {
    buscar() {
      this.search = this.filtro;
      this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/datos-base/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    }

    //nuevo
  },
  created() {
    this.fetchData();
  }
};
</script>
