<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Nuevo Dato
        </v-btn>
      </template>
      <v-form>
        <v-card>
          <v-card-title>
            <span class="headline">Crear nuevo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-for="(item, index) of headers"
                  :key="index"
                >
                  <v-select
                    v-if="item.selectOpctions"
                    :items="item.selectOpctions"
                    :item-text="'text'"
                    :item-value="'value'"
                    v-model="dtoSave[item.value]"
                  ></v-select>
                  <v-text-field
                    v-else
                    :label="item.text + '*'"
                    v-model="dtoSave[item.value]"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>* campos requeridos</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn color="blue darken-1" text @click="save()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      url: process.env.VUE_APP_API,
      dialog: false,
      page: 1,
      pages: 1,
      datos: [],
      search: "",
      dtoSave: {},
      headers: [
        { text: "numeroregistro", align: "start", value: "numeroRegistro" },
        { text: "nombreasegurado", value: "nombreAsegurado" },
        { text: "apellidoasegurado", value: "apellidoAsegurado" },
        { text: "generoasegurado", value: "generoAsegurado" },
        { text: "estadoCivilAsegurado", valie: "estadoCivilAsegurado" },
        { text: "fechanacimientoasegurado", value: "fechaNacimientoAsegurado" },
        { text: "edadasegurado", value: "edadAsegurado" },
        { text: "numeroTelefonoTrabajo", value: "numeroTelefonoTrabajo" },
        { text: "numeroExtensionTrabajo", value: "numeroExtensionTrabajo" },
        { text: "numerodecasa", value: "numeroTelefonoCasa" },
        { text: "numeroTelefonoMovil", value: "numeroTelefonoMovil" },
        { text: "numeroFax", value: "numeroFax" },
        { text: "correotrabajo", value: "correoTrabajo" },
        { text: "correoPersonal", value: "correoPersonal" },
        { text: "direccion", value: "direccion" },
        { text: "numeroagenteproductor", value: "numeroAgenteProductor" },
        { text: "nombreagenteproductor", value: "nombreAgenteProductor" },
        { text: "empleador", value: "empleador" },
        { text: "idPersonal", value: "idPersonal" },
        { text: "plan-contrato-producto", value: "planContratoProducto" },
        { text: "producto", value: "producto" },
        { text: "deducibleomontoasegurado", value: "deducibleMontoAsegurado" },
        { text: "fechaaniversariopoliza", value: "fechaAniversarioPoliza" },
        { text: "prima_anual", value: "primaAnual" },
        { text: "estadodepolizaocontrato", value: "estadoPolizaContrato" },
        {
          text: "lineadenegocio",
          value: "lineaNegocio",
          selectOpctions: [
            { text: "Atencion medica", value: "8" },
            { text: "ADB", value: "14" },
            { text: "Vida Universal BMIC", value: "15" },
            { text: "LTC Bmi Platinum", value: "23" },
            { text: "Vida a termino BMII", value: "54" },
            { text: "BMI Preferred Inversion", value: "55" }
          ]
        },
        { text: "numeropolizacontrato", value: "numeroPolizaContrato" },
        { text: "fechaefectividad", value: "fechaEfectividad" },
        { text: "fecha_cancelacion", value: "fechaCancelacion" },
        { text: "nombredeldueñodelapoliza", value: "nombrePropietarioPoliza" },
        {
          text: "apellidodeldueñodelapoliza",
          value: "apellidoPropietarioPoliza"
        },
        { text: "fecharecibido", value: "fechaRecibido" },
        { text: "estadodelcliente", value: "estadoCliente" },
        { text: "exclusionesactivas", value: "exclusionesActivas" },
        {
          text: "frecuenciapago",
          value: "frecuenciaPago",
          selectOpctions: [
            { text: "Anual", value: "Anual" },
            { text: "Mensual T/C", value: "Mensual T/C" },
            { text: "Semestral", value: "Semestral" },
            { text: "Trimestral", value: "Trimestral" }
          ]
        }
      ]
    };
  },
  methods: {
    buscar() {
      this.search = this.filtro;
      this.fetchData();
    },
    fetchData() {
      console.log("llamando api");
      //axios.get("data/datos.json").then(response => {
      axios
        .get(
          this.url +
            "/datos-base/paginado?page=" +
            this.page +
            "&limit=10" +
            "&search=" +
            this.search
        )
        .then(response => {
          this.datos = response.data?.items || [];
          this.pages = response.data?.meta?.totalPages || 0;
        });
    },
    save() {
      const data = JSON.parse(JSON.stringify(this.dtoSave));
      console.log(data);
      axios
        .post(this.url + "/datos-base", data)
        .then(response => {
          console.log(response);
          this.dialog = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
    //nuevo
  },
  created() {
    //this.fetchData();
  }
};
</script>
